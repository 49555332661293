<template>
  <b-overlay
    :show="!cargando"
    spinner-variant="primary"
  >
    <b-card>
      <b-form @submit.prevent="$emit('processProducto', producto)">
      <!-- Producto Info: Input Fields -->
        <b-form>
          <b-card-title>Información</b-card-title>
          <b-row>
            <!-- Field: Imagen -->
            <b-col
              cols="12"
              md="12"
            >

              <b-form-group
                label-for="imagen"
              >
                <!-- Media -->
                <b-media class="mb-2">
                  <template #aside>
                    <b-avatar
                      ref="previewEl"
                      :src="producto.imagen"
                      :text="producto.nombre == ''
                        ? 'P'
                        : avatarText(producto.nombre)"
                      size="90px"
                      rounded
                      variant="light-success"
                      :title="producto.nombre == ''
                        ? 'P'
                        : avatarText(producto.nombre)"
                    />
                  </template>
                  <h4 class="mb-1">
                    <span v-if="producto.codigo">
                      {{ producto.codigo }}
                    </span>
                    <span v-if="producto.codigo && producto.nombre">
                      -
                    </span>
                    <span v-if="producto.nombre">
                      {{ producto.nombre }}
                    </span>
                  </h4>
                  <div class="d-flex flex-wrap">
                    <b-button
                      variant="primary"
                      @click="$refs.refInputEl.click()"
                    >
                      <input
                        :id="producto.imagen"
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @input="inputImageRenderer"
                        accept="image/png,image/jpeg,image/jpg"
                        @change="onFileSelected"
                      >
                      <span class="d-none d-sm-inline">
                        Seleccionar
                      </span>
                      <feather-icon
                        icon="EditIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      class="ml-1"
                      @click="producto.imagen = ''"
                    >
                      <span class="d-none d-sm-inline">Eliminar</span>
                      <feather-icon
                        icon="TrashIcon"
                        class="d-inline d-sm-none"
                      />
                    </b-button>
                  </div>
                </b-media>
              </b-form-group>
            </b-col>

            <!-- Field: Nombre -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Nombre"
                label-for="nombre"
              >
                <b-form-input
                  id="nombre"
                  v-model="producto.nombre"
                  :state="$v.producto.nombre.$invalid == true
                    ? false
                    : null"
                  placeholder="Introduce el nombre"
                  @input="$v.producto.$touch"
                />
                <b-form-invalid-feedback
                  v-if="$v.producto.$dirty"
                  id="nombreInfo"
                >
                  El campo nombre
                  <span v-if="!$v.producto.nombre.required"> es requerido.</span>
                  <span v-if="!$v.producto.nombre.maxLength"> debe tener max 80 caracteres.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: codigo -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Código"
                label-for="codigo"
              >
                <b-form-input
                  id="codigo"
                  v-model="producto.codigo"
                  :state="$v.producto.codigo.$invalid == true
                    ? false
                    : null"
                  placeholder="Introduce el código"
                  @input="$v.producto.$touch"
                />
                <b-form-invalid-feedback
                  v-if="$v.producto.$dirty"
                  id="codigoInfo"
                >
                  El campo código
                  <span v-if="!$v.producto.codigo.required"> es requerido.</span>
                  <span v-if="!$v.producto.codigo.maxLength"> debe tener max 30 caracteres.</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Field: Stock -->
            <b-col
              cols="12"
              md="2"
            >
              <b-form-group
                label="Stock"
                label-for="stock"
              >

                <b-form-input
                  id="stock"
                  v-model="producto.stock"
                  type="number"
                  class="form-control"
                  :raw="false"
                  placeholder="Introduce el stock"
                  @keyup="producto.stock = soloNumeros(producto.stock)"
                  @blur="producto.stock = soloNumeros(producto.stock)"
                />
              </b-form-group>
            </b-col>

            <!-- Field: UNIDAD -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Unidad"
                label-for="unidad"
              >
                <v-select
                  v-model="producto.unidad"
                  placeholder="Selecciona una unidad"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="unidades"
                  :reduce="option => option.value"
                  input-id="unidad"
                />
              </b-form-group>
            </b-col>

            <!-- Field: idCategoria -->
            <!-- TODO: -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Categoría"
                label-for="categoria"
              >
                <v-select
                  v-model="producto.idCategoria"
                  placeholder="Selecciona una categoria"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoriasOption"
                  label="text"
                  :reduce="option => option.value"
                  input-id="idCategoria"
                />
                  <!-- :reduce="option => option.value" -->

              </b-form-group>
            </b-col>

            <!-- Field: idUbicacion -->
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="Ubicación"
                label-for="ubicacion"
              >
                <v-select
                  v-model="producto.idUbicacion"
                  placeholder="Selecciona una ubicación"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="ubicacionesOption"
                  :reduce="option => option.value"
                  input-id="idUbicacion"
                />
              </b-form-group>
            </b-col>

            <!-- Field: descripcion -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Descripción"
                label-for="descripcion"
              >
                <b-form-textarea
                  id="descripcion"
                  v-model="producto.descripcion"
                  placeholder="Introduce la descripción"
                  rows="3"
                  max-rows="3"
                  @input="$v.producto.$touch"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="6"
            >
              <b-card-title
                class="text-center mt-1-alarma"
              >
                Alarmas
              </b-card-title>
              <b-row>

                <!-- Field: Alarma Rojo -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <span
                    class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer"
                  />
                  <span>Roja</span>
                  <b-form-group
                    label-for="tramoDesdeRojo"
                  >

                    <b-form-input
                      id="tramoDesdeRojo"
                      v-model="tramoDesdeRojo"
                      :state="$v.producto.tramoDesdeRojo.$invalid == true
                        ? false
                        : null"
                      placeholder="Introduce la alarma roja"
                      @input="$v.producto.$touch"
                      @blur="soloNumerosTramo"
                      @keyup="tramoDesdeRojo = soloNumeros(tramoDesdeRojo)"
                    />
                    <b-form-invalid-feedback
                      v-if="$v.producto.$dirty"
                      id="alarmaRojoInfo"
                    >
                      El campo rojo
                      <span v-if="!$v.producto.tramoDesdeRojo.required"> es requerido.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Field: Alarma Amarillo -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <span
                    class="bullet bullet-warning svg-font-small-3 mr-50 cursor-pointer"
                  />
                  <span>Amarilla</span>
                  <b-form-group
                    label-for="tramoDesdeAmarillo"
                  >
                    <b-form-input
                      id="tramoDesdeAmarillo"
                      v-model="tramoDesdeAmarillo"
                      :state="$v.producto.tramoDesdeAmarillo.$invalid == true
                        ? false
                        : null"
                      placeholder="Introduce la alarma amarilla"
                      @input="$v.producto.$touch"
                      @blur="soloNumerosTramo"
                      @keyup="tramoDesdeAmarillo = soloNumeros(tramoDesdeAmarillo)"
                    />
                    <b-form-invalid-feedback
                      v-if="$v.producto.$dirty"
                      id="alarmaAmarilloInfo"
                    >
                      El campo amarillo
                      <span v-if="!$v.producto.tramoDesdeAmarillo.required"> es requerido.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <!-- Field: Alarma Verde -->
                <b-col
                  cols="12"
                  md="4"
                >
                  <span
                    class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer"
                  />
                  <span>Verde</span>
                  <b-form-group
                    label-for="tramoDesdeVerde"
                  >
                    <b-form-input
                      id="tramoDesdeVerde"
                      v-model="tramoDesdeVerde"
                      :state="$v.producto.tramoDesdeVerde.$invalid == true
                      ? false
                      : null"
                      placeholder="Introduce la alarma verde"
                      @input="$v.producto.$touch"
                      @blur="soloNumerosTramo"
                      @keyup="tramoDesdeVerde = soloNumeros(tramoDesdeVerde)"
                    />
                    <b-form-invalid-feedback
                      v-if="$v.producto.$dirty"
                      id="alarmaVerdeInfo"
                    >
                      El campo verde
                      <span v-if="!$v.producto.tramoDesdeVerde.required"> es requerido.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

            </b-col>
          </b-row>

          <colLinea />
          <b-card-title class="mt-2">Precios por cantidad</b-card-title>
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <precioCantidad
                :preciosCantidad.sync="producto.preciosCantidad"
                :v.sync="$v"
              />
            </b-col>

          </b-row>
          <colLinea />
        </b-form>
        <!-- Action Buttons -->
        <btnSubmit
          v-if="cargando"
          :btnDisabled="$v.producto.$invalid"
          :btnText="productoSubmit"
        />
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback,
  BFormTextarea, BCardTitle, BMedia, BButton, BAvatar, BOverlay, BCard,
} from 'bootstrap-vue'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import { soloNumerosTramo, formatos } from '@/mixins/forms'
import { mapActions, mapGetters, mapState } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import vSelect from 'vue-select'

const btnSubmit = () => import('@/layouts/components/Recycled/Form/btnSubmit.vue')
const precioCantidad = () => import('@/layouts/components/Productos/ProductosFormPrecioCantidad.vue')
const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    BCardTitle,
    BMedia,
    BButton,
    BAvatar,
    vSelect,
    BOverlay,
    BCard,

    btnSubmit,
    precioCantidad,
    colLinea,
  },
  props: {
    producto: {
      type: Object,
      required: true,
    },
    productoSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Proveedor',
    },
  },
  mixins: [validationMixin, soloNumerosTramo, formatos],
  data() {
    return {
      idCategoria: null,
      idUbicacion: null,
      tramoDesdeRojo: Number(this.producto.tramoDesdeRojo),
      tramoDesdeAmarillo: Number(this.producto.tramoDesdeAmarillo),
      tramoDesdeVerde: Number(this.producto.tramoDesdeVerde),
      categoriasOption: [],
      ubicacionesOption: [],
      cargando: false,
    }
  },
  computed: {
    ...mapGetters({
      getCategoriasActivas: 'categorias/getCategoriasActivas',
      getUbicacionesActivas: 'ubicaciones/getUbicacionesActivas',
      getCategorias: 'categorias/getCategorias',
      getUbicaciones: 'ubicaciones/getUbicaciones',
      getAlarmas: 'alarmas/getAlarmas',
    }),
    ...mapState('productos', {
      unidades: state => state.unidades,
    }),
  },
  watch: {
    tramoDesdeRojo() {
      this.producto.tramoDesdeRojo = this.tramoDesdeRojo
    },
    tramoDesdeAmarillo() {
      this.producto.tramoDesdeAmarillo = this.tramoDesdeAmarillo
    },
    tramoDesdeVerde() {
      this.producto.tramoDesdeVerde = this.tramoDesdeVerde
    },
  },
  validations() {
    return {
      producto: {
        codigo: {
          required,
          maxLength: maxLength(30),
        },
        nombre: {
          required,
          maxLength: maxLength(80),
        },
        tramoDesdeRojo: {
          required,
        },
        tramoDesdeAmarillo: {
          required,
        },
        tramoDesdeVerde: {
          required,
        },
      },
    }
  },
  mounted() {
    this.fetchCategorias().then(() => {
      this.fetchCategoriasActivas().then(() => {
        this.setCategoriasActivas()
      })
    })

    this.fetchUbicaciones().then(() => {
      this.fetchUbicacionesActivas().then(() => {
        this.setUbicacionesActivas()
      })
    })
    this.fetchAlarmas()

    this.tramoDesdeRojo = (this.producto.tramoDesdeRojo === null)
      ? this.getAlarmas.tramoDesdeRojo
      : this.producto.tramoDesdeRojo
    this.tramoDesdeAmarillo = (this.producto.tramoDesdeAmarillo === null)
      ? this.getAlarmas.tramoDesdeAmarillo
      : this.producto.tramoDesdeAmarillo
    this.tramoDesdeVerde = (this.producto.tramoDesdeVerde === null)
      ? this.getAlarmas.tramoDesdeVerde
      : this.producto.tramoDesdeVerde
  },
  methods: {
    ...mapActions({
      fetchCategoriasActivas: 'categorias/fetchCategoriasActivas',
      fetchUbicacionesActivas: 'ubicaciones/fetchUbicacionesActivas',
      fetchCategorias: 'categorias/fetchCategorias',
      fetchUbicaciones: 'ubicaciones/fetchUbicaciones',
      fetchAlarmas: 'alarmas/fetchAlarmas',
    }),
    setCategoriasActivas() {
      this.getCategoriasActivas.forEach(categoria => {
        this.categoriasOption.push({
          value: `${categoria.id}`,
          text: `${categoria.nombre}`,
        })
      })

      if (this.producto.idCategoria !== null) {
        const findCategorias = this.categoriasOption.find(
          categoria => categoria.value === this.producto.idCategoria,
        )
        const categoriaInactiva = this.getCategorias.find(
          categoriaIn => categoriaIn.id === this.producto.idCategoria,
        )
        if (!findCategorias && categoriaInactiva) {
          this.categoriasOption.push({
            value: this.producto.idCategoria,
            text: `${categoriaInactiva.nombre} (inactivo)`,
          })
        }
      }
    },
    setUbicacionesActivas() {
      this.getUbicacionesActivas.forEach(ubicacion => {
        this.ubicacionesOption.push({
          value: `${ubicacion.id}`,
          text: `${ubicacion.nombre}`,
        })
      })
      if (this.producto.idUbicacion !== null) {
        const findUbicaciones = this.ubicacionesOption.find(
          ubicacion => ubicacion.value === this.producto.idUbicacion,
        )
        const ubicacionInactiva = this.getUbicaciones.find(
          ubicacionIn => ubicacionIn.id === this.producto.idUbicacion,
        )
        if (!findUbicaciones && ubicacionInactiva) {
          this.ubicacionesOption.push({
            value: this.producto.idUbicacion,
            text: `${ubicacionInactiva.nombre} (inactivo)`,
          })
        }
      }
      this.cargando = true
    },
    onFileSelected(e) {
      // Obtiene primera Imagen
      const file = e.target.files[0]
      this.cargarImg(file)
    },
    cargarImg(file) {
      // Objeto que permite trabajar con archivos
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.producto.imagen = e.target.result
      }
    },
    soloNumerosStock() {
      const expRegNumero = /[^\d]/g
      if (this.producto.stock != null) {
        const validation = expRegNumero.test(this.producto.stock)
        if (validation) {
          this.producto.stock = this.producto.stock.replace(expRegNumero, '')
        }
      }
      if (this.producto.stock.length === 0) {
        this.producto.stock = '0'
      }
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.producto.imagen = base64
    })

    return {
      avatarText,
      previewEl,
      refInputEl,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
  .mt-1-alarma {
    margin-top: 9px;
  }
</style>
